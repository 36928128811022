import { Alert, Button, Checkbox, Drawer, Flex, Loader, Modal, Popover, Progress, ScrollArea, Table, Text, ThemeIcon, useMantineTheme } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import useApi from "../../hooks/useApi";
import { Result } from "react-spreadsheet-import/types/types";
import { IconCheck, IconDatabaseImport, IconX } from "@tabler/icons-react";
import xlsx from "json-as-xlsx";
import dayjs from "dayjs";
import { notifications } from "@mantine/notifications";
import { createStudent, updateStudent } from "../../api/routes/students";
import { useSetState } from "@mantine/hooks";

export default ({
    onSubmit,
}: {
    onSubmit?: (data: any, file: File) => void;
}) => {

    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useSetState({
        update_by_email: false,
    });

    const departmentsApiInterceptor = useApi<any>({ key: 'departments', params: { limit: 99999 } });
    const academicYearsApiInterceptor = useApi<any>({ key: 'academic_years', params: { limit: 99999 } });
    const religionApiInterceptor = useApi<any>({ key: 'students_metaenum', params: { meta_key: 'religion', limit: 99999 } });
    const legalGuardianApiInterceptor = useApi<any>({ key: 'students_metaenum', params: { meta_key: 'legal_guardian', limit: 99999 } });
    const nationalityApiInterceptor = useApi<any>({ key: 'students_metaenum', params: { meta_key: 'nationality', limit: 99999 } });
    const channelApiInterceptor = useApi<any>({ key: 'students_metaenum', params: { meta_key: 'registration_channel', limit: 99999 } });
    const gateApiInterceptor = useApi<any>({ key: 'students_metaenum', params: { meta_key: 'registration_gate', limit: 99999 } });
    const workApiInterceptor = useApi<any>({ key: 'students_metaenum', params: { meta_key: 'work', limit: 99999 } });
    const provincesApiInterceptor = useApi<any>({ key: 'students_metaenum', params: { meta_key: 'province', limit: 99999 } });
    const currentResultApiInterceptor = useApi<any>({ key: 'students_metaenum', params: { meta_key: 'current_result', limit: 99999 } });

    const fields = useMemo(() => ([
        {
            label: t("المعرف (ID)"),
            key: "id",
            alternateMatches: ["المعرف", "ID"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("رقم الهوية"),
            key: "id_number",
            alternateMatches: ["رقم الهوية"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("البريد الالكتروني"),
            key: "email",
            alternateMatches: ["البريد الالكتروني", "البريد"],
            fieldType: {
                type: "input",
            },

            // validations: [
            //     {
            //         rule: "unique",
            //         errorMessage: t("البريد الالكتروني مكرر في الجدول"),
            //         level: "error",
            //     },
            // ],
        },
        {
            label: t("الاسم"),
            key: "first_name",
            alternateMatches: ["الاسم", "اسم الطالب", "الاسم الأول"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("اسم الأب"),
            key: "second_name",
            alternateMatches: ["اسم الأب", "الاسم الثاني"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("اسم الجد"),
            key: "third_name",
            alternateMatches: ["اسم الجد", "الاسم الثالث"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("اسم الجد الثاني"),
            key: "fourth_name",
            alternateMatches: ["اسم الجد الثاني", "الاسم الرابع"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("اللقب"),
            key: "surname",
            alternateMatches: ["اللقب"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("الهاتف"),
            key: "phone",
            alternateMatches: ["الهاتف", "رقم هاتف الطالب"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("الجنس"),
            key: "gender",
            alternateMatches: ["الجنس"],
            fieldType: {
                type: "select",
                options: [
                    { label: t("ذكر"), value: 'male' },
                    { label: t("انثى"), value: 'female' },
                ],
            },
        },
        {
            label: t("حالة الحساب"),
            key: "account_status",
            alternateMatches: ["حالة الحساب"],
            fieldType: {
                type: "select",
                options: [
                    { label: t("نشط"), value: 'active' },
                    { label: t("موقف"), value: 'suspended' },
                ],
            },
        },
        {
            label: t("حالة الطالب"),
            key: "student_status",
            alternateMatches: ["حالة الطالب"],
            fieldType: {
                type: "select",
                options: [
                    { label: t("مستمر"), value: 'continuing' },
                    { label: t("غير مستمر"), value: 'suspended' },
                    { label: t("خريج"), value: 'graduated' },
                    { label: t("منسحب"), value: 'dropout' },
                    { label: t("مؤهل (غير مفعل)"), value: 'eligible' },
                    { label: t("معلق (غير مفعل)"), value: 'pending' },
                ],
            },
        },
        {
            label: t("الديانة"),
            key: "religion",
            alternateMatches: ["الديانة"],
            fieldType: {
                type: "select",
                options: religionApiInterceptor.query.data?.data?.map((item: any) => ({
                    label: item.meta_value,
                    value: item.id,
                })) || [],
            },
        },
        {
            label: t("القسم"),
            key: "department_id",
            alternateMatches: ["القسم"],
            fieldType: {
                type: "select",
                options: departmentsApiInterceptor.query.data?.data?.map((item: any) => {
                    let name = item.name;
                    // remove 'قسم' from the name
                    if (name.startsWith('قسم ')) {
                        name = name.replace('قسم ', '');
                    }
                    return {
                        label: name,
                        value: item.id,
                    };
                }) || [],
            },
        },
        {
            label: t("المرحلة"),
            key: "stage",
            alternateMatches: ["المرحلة"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("نوع الدراسة"),
            key: "study_type",
            alternateMatches: ["نوع الدراسة"],
            fieldType: {
                type: "select",
                options: [
                    { label: t("صباحي"), value: 'morning' },
                    { label: t("مسائي"), value: 'evening' },
                ],
            },
        },
        {
            label: t("تاريخ الميلاد"),
            key: "birth_date",
            alternateMatches: ["تاريخ الميلاد", "تاريخ ميلاد الطالب"],
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "regex",
                    value: "^$|^\\d{4}[-/]\\d{1,2}[-/]\\d{1,2}$",
                    errorMessage: t("الرجاء إدخال التاريخ بالصيغة الصحيحة YYYY-MM-DD"),
                    level: "error",
                },
            ],
        },
        {
            label: t("محل الميلاد"),
            key: "birth_place",
            alternateMatches: ["محل الميلاد", "محل الولادة"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("ولي الأمر"),
            key: "legal_guardian",
            alternateMatches: ["ولي الأمر", "ولي الامر"],
            fieldType: {
                type: "select",
                options: legalGuardianApiInterceptor.query.data?.data?.map((item: any) => ({
                    label: item.meta_value,
                    value: item.id,
                })) || [],
            },
        },
        {
            label: t("اسم ولي الأمر"),
            key: "legal_guardian_name",
            alternateMatches: ["اسم ولي الأمر", "اسم ولي الامر"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("عمل ولي الأمر"),
            key: "legal_guardian_work",
            alternateMatches: ["عمل ولي الأمر", "مهنة ولي الامر"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("هاتف ولي الأمر"),
            key: "legal_guardian_phone",
            alternateMatches: ["هاتف ولي الأمر", "رقم هاتف ولي امر الطالب"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("اسم الأم"),
            key: "mother_name",
            alternateMatches: ["اسم الأم", "اسم الام الثلاثي"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("عمل الأم"),
            key: "mother_work",
            alternateMatches: ["عمل الأم", "مهنة الام"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("الجنسية"),
            key: "nationality",
            alternateMatches: ["الجنسية"],
            fieldType: {
                type: "select",
                options: nationalityApiInterceptor.query.data?.data?.map((item: any) => ({
                    label: item.meta_value,
                    value: item.id,
                })) || [],
            },
        },
        {
            label: t("قناة القبول"),
            key: "registration_channel",
            alternateMatches: ["قناة القبول"],
            fieldType: {
                type: "select",
                options: channelApiInterceptor.query.data?.data?.map((item: any) => ({
                    label: item.meta_value,
                    value: item.id,
                })) || [],
            },
        },
        {
            label: t("بوابة القبول"),
            key: "registration_gate",
            alternateMatches: ["بوابة القبول", "نافذة القبول"],
            fieldType: {
                type: "select",
                options: gateApiInterceptor.query.data?.data?.map((item: any) => ({
                    label: item.meta_value,
                    value: item.id,
                })) || [],
            },
        },
        {
            label: t("عام القبول"),
            key: "registration_academic_year",
            alternateMatches: ["عام القبول", "سنة قبول الطالب"],
            fieldType: {
                type: "select",
                options: academicYearsApiInterceptor.query.data?.data?.map((item: any) => {
                    let name = item.name;
                    let parts = name.split("-");
                    // reverse
                    parts = parts.reverse();
                    // implode
                    name = parts.join("-");
                    return {
                        label: name,
                        value: item.id,
                    };
                }) || [],
            },
        },
        {
            label: t("سنة التسكين"),
            key: "host_academic_year",
            alternateMatches: ["سنة التسكين", "سنة التسكين / النقل"],
            fieldType: {
                type: "select",
                options: academicYearsApiInterceptor.query.data?.data?.map((item: any) => {
                    let name = item.name;
                    let parts = name.split("-");
                    // reverse
                    parts = parts.reverse();
                    // implode
                    name = parts.join("-");
                    return {
                        label: name,
                        value: item.id,
                    };
                }) || [],
            },
        },
        {
            label: t("العنوان السابق"),
            key: "previous_address",
            alternateMatches: ["العنوان السابق"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("العنوان الحالي"),
            key: "current_address",
            alternateMatches: ["العنوان الحالي"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("العمل"),
            key: "work",
            alternateMatches: ["العمل", "الطالب موظف؟"],
            fieldType: {
                type: "select",
                options: workApiInterceptor.query.data?.data?.map((item: any) => ({
                    label: item.meta_value,
                    value: item.id,
                })) || [],
            },
        },
        {
            label: t("الملاحظات"),
            key: "notes",
            alternateMatches: ["الملاحظات الخاصة بالتسجيل"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("لديه معهد"),
            key: "instituteـstudent",
            alternateMatches: ["لديه معهد"],
            fieldType: {
                type: "select",
                options: [
                    { label: t("نعم"), value: 'yes' },
                    { label: t("لا"), value: 'no' },
                ],
            },
        },
        {
            label: t("تخصص المعهد"),
            key: "instituteـdepartment",
            alternateMatches: ["تخصص المعهد"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("المحافظة"),
            key: "province",
            alternateMatches: ["المحافظة", "محافظة السكن"],
            fieldType: {
                type: "select",
                options: provincesApiInterceptor.query.data?.data?.map((item: any) => ({
                    label: item.meta_value,
                    value: item.id,
                })) || [],
            },
        },
        {
            label: t("نتيجة الطالب للعام الحالي"),
            key: "current_result",
            alternateMatches: ["نتيجة الطالب للعام الحالي"],
            fieldType: {
                type: "select",
                options: currentResultApiInterceptor.query.data?.data?.map((item: any) => ({
                    label: item.meta_value,
                    value: item.id,
                })) || [],
            },
        }
    ]), [
        religionApiInterceptor.query.data,
        departmentsApiInterceptor.query.data,
        legalGuardianApiInterceptor.query.data,
        nationalityApiInterceptor.query.data,
        channelApiInterceptor.query.data,
        gateApiInterceptor.query.data,
        academicYearsApiInterceptor.query.data,
        workApiInterceptor.query.data,
        provincesApiInterceptor.query.data,
        currentResultApiInterceptor.query.data,
    ]);

    const [data, setData] = useState<any>(null);

    return (
        <>
            <Button
                size="xs"
                onClick={() => {
                    // setIsOpen(true);
                    setModalOpen(true);
                }}
                leftSection={<IconDatabaseImport size={16} />}
                variant="outline"
            >
                {t("إستيراد الطلبة")}
            </Button>
            <Modal opened={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Title>{t("خيارات الاستيراد")}</Modal.Title>
                <Modal.Body>
                    <Flex direction="column" gap="xs" pt={'md'}>
                        <Checkbox
                            label={t("قم بتحديث الطالب اذا كان البريد الالكتروني موجود")}
                            checked={options.update_by_email ?? false}
                            onChange={(event) => {
                                setOptions({ update_by_email: event.currentTarget.checked });
                            }}
                        />
                        <Flex gap={'xs'}>
                            <Button
                                size="xs"
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                                color="red"
                            >
                                {t("إغلاق")}
                            </Button>
                            <Button
                                size="xs"
                                onClick={() => {
                                    setModalOpen(false);
                                    setIsOpen(true);
                                }}
                            >
                                {t("التالي")}
                            </Button>
                        </Flex>
                    </Flex>
                </Modal.Body>
            </Modal>
            <ReactSpreadsheetImport
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                autoMapSelectValues={true}
                onSubmit={(data: Result<any>, file: File) => {
                    if (data.invalidData.length > 0) {
                        console.log("invalidData", data.invalidData);

                        alert(t("تم العثور على بيانات غير صحيحة في الجدول"));
                        return;
                    }

                    const validData = data.validData || [];

                    if (!validData.length) {
                        alert(t("لم يتم العثور على بيانات صحيحة في الجدول"));
                        return;
                    }

                    setData(validData);
                    onSubmit && onSubmit(validData, file);

                }}
                fields={fields}
            />
            <ImportDrawer data={data} options={options} />
        </>
    )

}

export const ImportDrawer = ({
    data,
    options,
}: {
    data: any;
    options?: any;
}) => {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [importedData, setImportedData] = useState<any>(null);
    const [invalidImports, setInavlidImports] = useState<any>([]);
    const [cursor, setCursor] = useState(-1);
    const [completed, setCompleted] = useState(false);

    const [progress, setProgress] = useState(0);

    const studentsApiInterceptor = useApi<any>({
        key: 'students',
        disableNotification: true,
        // disableQuery: true,
        onDone: (action, response) => {
            const item = {
                row: data[cursor],
                action,
                success: !Boolean(response.message),
                response,
            };
            importedData.push(item);
            if (!item.success) {
                invalidImports.push(item);
            }
            setCursor((prev) => prev + 1);

            const total = data.length;
            const current = importedData.length;
            const prg = (current / total) * 100;
            setProgress(Number(prg).toFixed(2) as any);
        }
    });

    useEffect(() => {
        if (data) {
            setImportedData([]);
            setInavlidImports([]);
            setCompleted(false);
            setIsOpen(true);
            setCursor(0);
        }
    }, [data]);

    const handleResponse = (response: any) => {
        console.log(response);

        const item = {
            row: data[cursor],
            success: !Boolean(response.message),
            response,
        };
        importedData.push(item);
        if (!item.success) {
            invalidImports.push(item);
        }
        setCursor((prev) => prev + 1);

        const total = data.length;
        const current = importedData.length;
        const prg = (current / total) * 100;
        setProgress(Number(prg).toFixed(2) as any);
    };

    useEffect(() => {
        if (!data || cursor === data?.length) {
            setCompleted(true);
            return;
        }

        const row = data[cursor];
        const newRow: any = {};
        Object.keys(row).forEach((key: string) => {
            if (row[key]) {
                newRow[key] = row[key];
            }
        });

        // if it counts id update
        if (newRow.id) {
            // studentsApiInterceptor.mutation.update(newRow.id, newRow);
            updateStudent(newRow.id, {
                ...newRow,
                options,
            }).then((response) => {
                handleResponse(response);
            }).catch((error) => {
                handleResponse(error);
            });
        } else {
            // studentsApiInterceptor.mutation.create(newRow);
            createStudent({
                ...newRow,
                options,
            }).then((response) => {
                handleResponse(response);
            }).catch((error) => {
                handleResponse(error);
            });
        }
    }, [cursor]);

    return (
        <Drawer
            title={t("استيراد بيانات الطلاب")}
            opened={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
            closeOnClickOutside={false}
            position="right"
            size={'calc(100vw - 320px)'}
        >
            <Flex w={'100%'} p={'md'} gap={'xs'}>
                <Flex gap={'xs'}>
                    {
                        completed ? (
                            <ThemeIcon radius="xl" color={theme.colors.teal[6]} variant="filled">
                                <IconCheck size={20} />
                            </ThemeIcon>
                        ) : (
                            <Loader />
                        )
                    }
                    <Text fw={'bold'}>
                        {
                            completed ? t("اكتمل الاستيراد") : (t("جاري الاستيراد") + "...")
                        }
                    </Text>
                </Flex>
                <Flex direction={"column"} w={'100%'}>
                    <Progress.Root radius="xs" size="xl" w={'100%'}>
                        <Progress.Section value={progress} striped animated={!completed}>
                            <Progress.Label>{progress}%</Progress.Label>
                        </Progress.Section>
                    </Progress.Root>
                    <Text size={'xs'} color={'gray'}>{importedData?.length} / {data?.length}</Text>
                </Flex>
            </Flex>
            {
                (Boolean(invalidImports.length) || completed) && (
                    <Flex justify="center" align="center" direction="column" style={{ height: 200 }}>
                        <Alert title={t("تنبيه")} color={invalidImports.length ? 'red' : 'teal'} w={'100%'}>
                            <Flex direction={'column'} gap={'xs'}>
                                <Text fs={'sm'}>{invalidImports.length ? t("لديك بعض الصفوف لم يتم إستيرادها بنجاح") : t("تم استيراد البيانات بنجاح")}</Text>
                                {
                                    invalidImports.length > 0 && (
                                        <Button
                                            color={'red'}
                                            size={'xs'}
                                            onClick={() => {
                                                const headers = [
                                                    ...Object.keys(invalidImports[0].row),
                                                    "success",
                                                    "message",
                                                ];
                                                xlsx([
                                                    {
                                                        sheet: "Sheet 1",
                                                        columns: [
                                                            ...headers.map((header: any) => ({ label: header, value: header })),
                                                        ],
                                                        content: [
                                                            ...invalidImports.map((item: any) => Object.keys(headers).reduce((acc: any, key: any) => {
                                                                const realKey = headers[key];
                                                                if (realKey === 'success') {
                                                                    return {
                                                                        ...acc,
                                                                        [realKey]: item.success ? 'نعم' : 'لا',
                                                                    }
                                                                } else if (realKey === 'message') {
                                                                    return {
                                                                        ...acc,
                                                                        [realKey]: item.response?.message || '',
                                                                    }
                                                                }
                                                                return {
                                                                    ...acc,
                                                                    [realKey]: item.row[realKey] || '',
                                                                }
                                                            }, {})),
                                                        ],
                                                    }
                                                ], {
                                                    fileName: "invalid-results-" + dayjs().format('Invalid students import YYYY-MM-DD-HH-mm-ss') + ".xlsx",
                                                    extraLength: 3,
                                                    writeMode: "writeFile",
                                                    writeOptions: {},
                                                    RTL: true,
                                                }, (sheet: any) => {
                                                    notifications.show({
                                                        title: t('تم التحميل'),
                                                        message: t('تم تحميل النتائج بنجاح'),
                                                        color: 'green',
                                                    });
                                                });
                                            }}
                                        >
                                            {t("تحميل الصفوف غير المستوردة")}
                                        </Button>
                                    )
                                }
                            </Flex>
                        </Alert>
                    </Flex>
                )
            }
            {
                Boolean(invalidImports.length) /*&& Boolean(data) && data.length > 0*/ && (
                    <Flex direction="column">
                        <Text size="sm" fw="bold">{t("آخر 50 صف غير مستورد من أصل")} {invalidImports.length} {t("صف غير مستورد")}</Text>
                        <ScrollArea>
                            <Table>
                                <Table.Thead>
                                    <Table.Tr>
                                        <Table.Th>{t("الحالة")}</Table.Th>
                                        {Object.keys(data[0]).map((key: string) => (
                                            <Table.Th key={key}>{key}</Table.Th>
                                        ))}
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    {data.slice(-50).map((row: any, index: number) => importedData[index]?.success || cursor <= index ? null : (
                                        <Table.Tr key={index} style={Boolean(importedData.length) ? { backgroundColor: importedData[index]?.success ? theme.colors.teal[0] : theme.colors.red[0] } : {}}>
                                            <Table.Td>
                                                {
                                                    cursor <= index ? (
                                                        <Loader size="sm" />
                                                    ) : (
                                                        importedData[index]?.success ? (
                                                            <ThemeIcon radius="xl" color={theme.colors.teal[6]} variant="filled">
                                                                <IconCheck size={13} />
                                                            </ThemeIcon>
                                                        ) : (
                                                            <Popover width={200} position="bottom" withArrow shadow="md">
                                                                <Popover.Target>
                                                                    <ThemeIcon radius="xl" color={theme.colors.red[6]} variant="filled" style={{ cursor: 'pointer' }}>
                                                                        <IconX size={13} />
                                                                    </ThemeIcon>
                                                                </Popover.Target>
                                                                <Popover.Dropdown>
                                                                    <Text size="xs">{importedData[index]?.response?.message || t("حدث خطأ أثناء الاستيراد")}</Text>
                                                                </Popover.Dropdown>
                                                            </Popover>
                                                        )
                                                    )
                                                }
                                            </Table.Td>
                                            {Object.keys(row).map((key: string) => (
                                                <Table.Td key={key}>{row[key]}</Table.Td>
                                            ))}
                                        </Table.Tr>
                                    ))}
                                </Table.Tbody>
                            </Table>
                        </ScrollArea>
                    </Flex>
                )
            }
        </Drawer>
    )

}